


























import { Component, Mixins, Ref, Vue } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import EntranceStudentHistoryHeader from '@/components/organisms/EntranceStudentHistoryHeader.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import SelectSubject from '@/components/atoms/SelectSubject.vue'
import PretestInformation from '@/components/organisms/PretestInformation.vue'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import SelectWide from '@/components/molecules/SelectWide.vue'
import ModalPretestInformation from '@/components/organisms/ModalPretestInformation.vue'
import { EntranceResult, EntranceInfo } from '@/models/api/history/entrance.ts'
import _ from 'lodash'

@Component({
  components: {
    SidebarSwitcher,
    EntranceStudentHistoryHeader,
    ColoredBox,
    SelectSubject,
    PretestInformation,
    SelectWide,
    ModalPretestInformation,
  },
})
export default class PretestSituation extends Mixins(ClassModeChangeAble) {
  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId

  @Ref() modalPretestInformation!: ModalPretestInformation

  private pretestResults: EntranceResult[] = []
  private entranceData: EntranceInfo = { school: '-', date: '-', countdown: '-' }

  private selectedSubject = 'all'
  private selectedCondition = ''

  private selectConditionOptions: { text: string; value: string }[] = [
    { text: '全条件', value: '' },
    { text: '全国高校入試問題', value: 'NP' },
    { text: '大問選択', value: 'NL' },
    { text: 'テーマ別', value: 'NT' },
  ]

  private async created(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.checkClassModeAndSideMenuMode()
    await this.setResultData()
    await this.setEntranceData()
    Vue.prototype.$loading.complete()
  }

  private async onChangeSelected() {
    Vue.prototype.$loading.start()
    await this.setResultData()
    Vue.prototype.$loading.complete()
  }

  private showModal(id: number): void {
    this.modalPretestInformation.showModal(id, this.userId)
  }

  private async setResultData(): Promise<void> {
    const params: { userId: number; subjectCode?: string; searchType?: string } = { userId: this.userId }
    if (this.selectedSubject !== 'all') params.subjectCode = this.selectedSubject
    if (this.selectedCondition !== '') params.searchType = this.selectedCondition
    Vue.prototype.$http.httpWithToken
      .get(`/history/entranceResult`, {
        params: params,
      })
      .then((res: any) => {
        this.pretestResults = res.data
      })
  }

  private async setEntranceData(): Promise<void> {
    Vue.prototype.$http.httpWithToken
      .get(`/history/entranceInformation`, {
        params: { userId: this.userId },
      })
      .then((res: any) => {
        if (!_.isEmpty(res.data)) this.entranceData = res.data
      })
  }
}
